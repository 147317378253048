// import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import { cloneDeep } from 'lodash'
import Vue from 'vue'
/**
 * 格式化商品项数据（只负责格式化数据，不请求接口）
 * @param itemLanguage[Object] 商品项多语言
 * @param config[Object] 商品项配置项
*/
export default class GoodsInfoFormat {
  constructor(itemLanguage, config) {
    this.itemLanguage = itemLanguage
    this.map = new Map() // 缓存处理过的商品数据，  item.goods_id做为key
    // this.goodsItemInfo = new GoodsItemInfo({ itemLanguage })
    this.config = config
  }

  async use(originList = []) {
    const products = cloneDeep(originList)

    const goods = this._getWaitList(products) // 获取没有处理过item
    if (!goods.length) return products // item都已经处理过了 直接返回。
    
    // const sheinClubPromotionInfo = this.config.showSheinClubDiscountValue || this.config.showSheinClubDiscountValueAfterPrice

    dealProductsPretreatInfo({
      products: goods,
      itemConfig: this.config,
      language: this.itemLanguage,
      promotionInfoFromServer: {},
    })
    
    // await this.goodsItemInfo.getProductInfo({
    //   goods,
    //   itemConfig: this.config,
    //   pageKey: 'page_collection',
    //   sceneKey: 'TWO_IN_A_ROW',
    //   requestFields: {
    //     sheinClubPromotionInfo, // 付费会员展示
    //   },
    // })
    return products
  }

  _getWaitList(products) {
    const waitList = []
    products.forEach(item => {
      if (this.map.has(item.goods_id)) {
        const cache = {
          ...this.map.get(item.goods_id),
          ...item,
        }
        Object.keys(cache).forEach(k => { // 解决筛选后数据
          Vue.set(item, k, cache[k])
        })
        item.cache = 1
        return
      }

      this.map.set(item.goods_id, item)
      waitList.push(item)
    })
    return waitList
  }
}
